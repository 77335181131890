import { Component } from 'react'
import styled from '@emotion/styled'
import { Icons, Box, StatusChip, ItemInfoModal, Image, RoundedLabel, SgtinInfoModal } from 'stylewhere/components'
import { GroupedItemRowProps, Products } from 'stylewhere/api'
import { MotionStyle } from 'framer-motion'
import { closeModal, openModal, hasChecklist } from 'stylewhere/shared/utils'
import { T, __ } from 'stylewhere/i18n'
import { AppStore } from 'stylewhere/shared'

interface State {
  modalVisible: boolean
}

type ItemDecodeResultType = 'itemFound' | 'productFound' | 'unknownDecoded' | 'unknown'

export class GroupedItemRow extends Component<GroupedItemRowProps, State> {
  static Skeleton = styled(Box)``

  roundedLabel = (label: string, value?: string, style?: MotionStyle) => (
    <Box
      row
      mr={15}
      style={{
        backgroundColor: '#EDEDED',
        borderRadius: 5,
        ...style,
      }}
      pv={5}
      ph={10}
    >
      <SmallLabel>{label.toUpperCase()}</SmallLabel>
      <SmallValue>{value ?? ' ---'}</SmallValue>
    </Box>
  )

  getShowItemModalHandler = (resultType: ItemDecodeResultType) => () => {
    const { decodedItem } = this.props
    if (decodedItem.item && !decodedItem.item.fakeItemID) {
      switch (resultType) {
        case 'itemFound':
          return openModal({
            id: 'item-modal',
            modal: <ItemInfoModal visible itemId={decodedItem.item.id} onClose={() => closeModal('item-modal')} />,
          })
        case 'productFound':
          return openModal({
            id: 'item-modal',
            modal: <SgtinInfoModal visible item={decodedItem.item} onClose={() => closeModal('item-modal')} />,
          })
      }
    }
  }

  isWarningZone = () => {
    const { operation, itemZoneBase, decodedItem } = this.props
    if (operation && operation.enabledCheckItemZone && itemZoneBase && decodedItem.item && decodedItem.item.zone) {
      return decodedItem.item.zone.id !== itemZoneBase
    }
    return false
  }

  getProductCode = (resultType: ItemDecodeResultType) => {
    const { decodedItem } = this.props
    switch (resultType) {
      case 'itemFound':
      case 'productFound':
        return decodedItem.item?.product?.code
      case 'unknownDecoded':
        return decodedItem.item?.product?.attributes?.ean?.value
      case 'unknown':
        return __(T.misc.unknown).toUpperCase()
    }
  }

  getIdentifierCode = (_resultType: ItemDecodeResultType) => {
    const { configuration, decodedItem } = this.props
    if (configuration?.useProductCode && decodedItem?.item?.product) {
      return decodedItem.item.product?.code
    }
    return decodedItem.identifierCode
  }

  getIdentifiers = () => {
    const { configuration, decodedItem } = this.props
    if (configuration && configuration.hideIdentifiers) {
      return []
    } else {
      let identifiers = decodedItem.item && decodedItem.item.identifiers ? decodedItem.item.identifiers : []
      if (!configuration || !configuration.useProductCode) {
        identifiers = identifiers.filter((idf) => idf.code !== decodedItem.identifierCode)
      }
      return identifiers
    }
  }

  getStatusForResultType = (
    resultType: ItemDecodeResultType
  ): [string, 'warning' | 'error' | 'default' | 'success'] => {
    switch (resultType) {
      case 'itemFound':
        return ['', 'success']
      case 'productFound':
        return [__(T.misc.unknown_tag), 'warning']
      case 'unknownDecoded':
        return [__(T.misc.unknown_product), 'warning']
      case 'unknown':
        return [__(T.misc.invalid_tag), 'error']
    }
  }

  sortStatusChip = (status: string) => {
    const { operation } = this.props
    const chipOrder = {
      error: -1,
      warning: 0,
      default: 1,
    }
    let statusType = chipOrder.default
    if (operation?.itemStatuses.warning.includes(status)) statusType = chipOrder.warning
    if (operation?.itemStatuses.error.includes(status)) statusType = chipOrder.error

    return statusType
  }

  renderItemRow = (resultType: ItemDecodeResultType) => {
    const { decodedItem, onActionItem, hideProductInfo, mb, configuration, operation, disabledCounter } = this.props
    const { product } = decodedItem.item || {}
    const showCoreAttributes = resultType !== 'unknown' && resultType !== 'unknownDecoded'
    const identifiers = this.getIdentifiers()
    const identifierCode = this.getIdentifierCode(resultType)
    const productCode = this.getProductCode(resultType)
    const showIdentifier = identifierCode || identifiers.length > 0
    const [statusForType, statusIndicator] = this.getStatusForResultType(resultType)
    const showItemDecodedStatuses = decodedItem.statuses && decodedItem.statuses.length > 0
    const read = decodedItem.detected ?? 0 // + (unexpected ?? 0)
    const withChecklist = hasChecklist(operation)
    const counterValue = decodedItem.expected ? `${read}/${decodedItem.expected}` : `${read}${withChecklist ? '/0' : ''}`
    const hasItemDetails = (['itemFound', 'productFound'] as ItemDecodeResultType[]).includes(resultType)

    return (
      <Row row mb={mb} onClick={hasItemDetails ? this.getShowItemModalHandler(resultType) : undefined} p={15}>
        {!hideProductInfo && product?.code && AppStore.getShowProductImage() ? (
          <Box center>
            <Image src={Products.getImageUrl(product)} width={100} height={100} />
          </Box>
        ) : (
          <Box center>
            <Icons.Rfid style={{ width: 100 }} />
          </Box>
        )}
        <Box flex justify={'center'} mr={0} ml={15}>
          {showIdentifier && (
            <Box row style={{ justifyContent: 'flex-start', flexWrap: 'wrap' }}>
              {identifierCode && (
                <RoundedLabel value={identifierCode} style={{ backgroundColor: '#333', color: '#FFF' }} />
              )}
              {identifiers.map((identifier) => (
                <RoundedLabel
                  style={{ backgroundColor: '#333', color: '#FFF' }}
                  label={identifier.role?.toUpperCase()}
                  value={identifier.code}
                />
              ))}
            </Box>
          )}
          {!hideProductInfo && (
            <Box pv={0} ph={0} mt={showIdentifier ? 8 : 0} mb={2}>
              {(!configuration || !configuration.useProductCode) && <b style={{ fontSize: 23 }}>{productCode}</b>}
              <span style={{ fontSize: 16 }}>{product?.description}</span>
              {showCoreAttributes && (
                <Box row style={{ justifyContent: 'flex-start', flexWrap: 'wrap' }}>
                  <RoundedLabel label={__(T.misc.size)} value={product?.size?.value || 'n/a'} />
                  <RoundedLabel label={__(T.misc.color)} value={product?.color?.value || 'n/a'} />
                </Box>
              )}
            </Box>
          )}

          {decodedItem.item && decodedItem.item.zone && decodedItem.item.zone.place && (
            <StatusChip
              status={`${decodedItem.item?.zone?.place.code} - ${decodedItem.item?.zone?.code}`}
              operation={operation}
              mt={8}
            />
          )}
          {(showItemDecodedStatuses || statusForType !== '') && (
            <Box row flexWrap>
              {showItemDecodedStatuses &&
                decodedItem.statuses
                  ?.sort(this.sortStatusChip)
                  .map((status, index) => <StatusChip status={status} operation={operation} index={index} />)}
              {statusForType !== '' && (
                <StatusChip status={statusForType.toUpperCase()} customStatusType={statusIndicator} />
              )}
            </Box>
          )}
          {this.isWarningZone() && (
            <StatusChip customStatusType="warning" status={__(T.misc.warning_zone_items)} operation={operation} />
          )}
        </Box>
        {withChecklist && !disabledCounter && (
          <Box center ml={15}>
            <SmallCounter
              mr={0}
              ph={20}
              detected={read}
              expected={decodedItem.expected ?? 0}
              unexpected={decodedItem.unexpected ?? 0}
              noChecklist={!withChecklist}
            >
              {counterValue}
            </SmallCounter>
          </Box>
        )}
        {!!onActionItem && (
          <Box center mr={10} onClick={() => onActionItem(this.props.decodedItem)}>
            <Icons.Confirmed style={{ width: 50 }} />
          </Box>
        )}
      </Row>
    )
  }

  render() {
    const { decodedItem } = this.props

    // 1. epc con item a sistema - itemFound
    // 2. epc decodificabile, item non a sistema, prodotto a sistema - productFound
    // 3. epc decodificabile, item non a sistema, prodotto non a sistema - unknownDecoded
    // 4. epc non decodificabile - renderUnknownItemRow

    let decodeStatus: ItemDecodeResultType

    if (decodedItem.item?.id) {
      decodeStatus = 'itemFound'
    } else if (decodedItem.item?.product?.id) {
      decodeStatus = 'productFound'
    } else if (decodedItem.item?.product?.attributes?.ean) {
      decodeStatus = 'unknownDecoded'
    } else {
      decodeStatus = 'unknown'
    }

    return this.renderItemRow(decodeStatus)
  }
}

const Row = styled(Box) <{ mb?}>`
  margin-bottom: ${({ mb }) => (mb ? mb + 'px' : '20px')};
  border: 2px solid #edecec;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  background-color: ${({ theme }) => theme.background2};
`

const ReadIdentifierCode = styled.label`
  font-weight: bold;
  font-size: 18pt;
`
const IdentifierCode = styled.label`
  font-size: 16pt;
`
const SmallValue = styled.label`
  font-weight: bold;
  font-size: 12pt;
  white-space: nowrap;
`
const SmallLabel = styled.label`
  font-size: 12pt;
  white-space: nowrap;
  margin-right: 5px;
`

const SmallCounter = styled(Box) <{
  detected?: number
  expected?: number
  unexpected?: number
  noChecklist?: boolean
}>`
  font-size: 50px;
  font-weight: bold;
  text-align: center;
  min-width: 100px;
  width: 100%;
  align-self: center;
  background-color: ${({ detected = 0, expected = 0, unexpected = 0, noChecklist = false }) => {
    if (noChecklist) return '#F0F0F0'
    switch (status) {
      case 'error':
        return '#FF8474'
      case 'warning':
        return '#FFEA67'
      case 'ignored':
        return '#DAE6ED'
      default:
        if (detected + unexpected > expected) return '#F2B57D'
        if (noChecklist) return '#75EBA8'
        if (unexpected > 0) return '#F2B57D'
        if (detected < expected) return '#EDEDED'
        if (detected === expected) return '#75EBA8'
        return '#F0F0F0'
    }
  }};
  border-radius: 10px;
`
